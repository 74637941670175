/* purgecss start ignore */
@tailwind base;

@tailwind components;
/* purgecss end ignore */

@tailwind utilities;

/* FONTS */
@font-face {
  font-family: 'Graphik Web';
  src: url('/fonts/Graphik-SuperItalic-Web.woff2') format('woff2'),
    url('/fonts/Graphik-SuperItalic-Web.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('/fonts/Graphik-Super-Web.woff2') format('woff2'),
    url('/fonts/Graphik-Super-Web.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('/fonts/Graphik-BlackItalic-Web.woff2') format('woff2'),
    url('/fonts/Graphik-BlackItalic-Web.woff') format('woff');
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('/fonts/Graphik-Black-Web.woff2') format('woff2'),
    url('/fonts/Graphik-Black-Web.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('/fonts/Graphik-BoldItalic-Web.woff2') format('woff2'),
    url('/fonts/Graphik-BoldItalic-Web.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('/fonts/Graphik-Bold-Web.woff2') format('woff2'),
    url('/fonts/Graphik-Bold-Web.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('/fonts/Graphik-SemiboldItalic-Web.woff2') format('woff2'),
    url('/fonts/Graphik-SemiboldItalic-Web.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('/fonts/Graphik-Semibold-Web.woff2') format('woff2'),
    url('/fonts/Graphik-Semibold-Web.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('/fonts/Graphik-MediumItalic-Web.woff2') format('woff2'),
    url('/fonts/Graphik-MediumItalic-Web.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('/fonts/Graphik-Medium-Web.woff2') format('woff2'),
    url('/fonts/Graphik-Medium-Web.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('/fonts/Graphik-RegularItalic-Web.woff2') format('woff2'),
    url('/fonts/Graphik-RegularItalic-Web.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('/fonts/Graphik-Regular-Web.woff2') format('woff2'),
    url('/fonts/Graphik-Regular-Web.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('/fonts/Graphik-LightItalic-Web.woff2') format('woff2'),
    url('/fonts/Graphik-LightItalic-Web.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('/fonts/Graphik-Light-Web.woff2') format('woff2'),
    url('/fonts/Graphik-Light-Web.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('/fonts/Graphik-ExtralightItalic-Web.woff2') format('woff2'),
    url('/fonts/Graphik-ExtralightItalic-Web.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('/fonts/Graphik-Extralight-Web.woff2') format('woff2'),
    url('/fonts/Graphik-Extralight-Web.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('/fonts/Graphik-ThinItalic-Web.woff2') format('woff2'),
    url('/fonts/Graphik-ThinItalic-Web.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik Web';
  src: url('/fonts/Graphik-Thin-Web.woff2') format('woff2'),
    url('/fonts/Graphik-Thin-Web.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}
/* END FONTS */

body {
  font-family: 'Graphik Web', sans-serif;
  color: #192543; /* graySecondary */
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.react-player > div {
  overflow: auto;
}

/* RANGE SLIDER STYLES */
input[type='range'] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type='range']:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

input[type='range']::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

/* Special styling for WebKit/Blink */
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #2392b2;
  cursor: pointer;
  margin-top: -7px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); /* Add cool effects to your sliders! */
}

/* All the same stuff for Firefox */
input[type='range']::-moz-range-thumb {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  height: 24px;
  width: 24px;
  border: 0;
  border-radius: 50%;
  background: #2392b2;
  cursor: pointer;
}

/* All the same stuff for IE */
input[type='range']::-ms-thumb {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #2392b2;
  cursor: pointer;
}

input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  /* background: #a7d3e0; */
  border-radius: 5px;
}

/* input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #2392b2;
} */

input[type='range']::-moz-range-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  background: #a7d3e0;
  border-radius: 5px;
}

input[type='range']::-moz-range-progress {
  width: 100%;
  height: 10px;
  cursor: pointer;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  background: #2392b2;
  border-radius: 5px;
}

input[type='range']::-ms-track {
  width: 100%;
  height: 10px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}
input[type='range']::-ms-fill-lower {
  background: #2392b2;
  border: 0.2px solid #010101;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
input[type='range']:focus::-ms-fill-lower {
  background: #2392b2;
}
input[type='range']::-ms-fill-upper {
  background: #a7d3e0;
  border: 0.2px solid #010101;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}
input[type='range']:focus::-ms-fill-upper {
  background: #a7d3e0;
}

/* to style all html strings */
.html-div ul {
  list-style: disc;
  list-style-position: outside;
}

.html-div ol {
  list-style: decimal;
  list-style-position: outside;
}

ol, ul {
  margin-left: 17px;
}

li {
  padding: 0.25rem 0;
}

.html-div p:empty {
  height: 0.75rem;
}

.fixedContainer {
  background-color: #ddd;
  position: fixed;
  padding: 2em;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* REACT RESPONSIVE CAROUSEL OVERRIDE */
.carousel {
  background: transparent !important;
}
/* .carousel-slider .slider-wrapper {
  max-height: 65vh !important;
}
.carousel-slider .slider-wrapper img {
  width: auto !important;
  max-height: 60vh !important;
  vertical-align: top;
  border: 0;
} */
.carousel-slider .control-dots {
  display: none;
}
.carousel-slider .slide {
  background: transparent !important;
}
.carousel-slider .control-arrow {
  top: 45% !important;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.7) !important;
  font-size: 26px;
  bottom: 0;
  margin-top: 0;
  padding: 5px;
}
.carousel-slider .control-arrow:hover {
  background: rgba(0, 0, 0, 0.7) !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#sticky-hero-wrapper::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#sticky-hero-wrapper {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Needed to avoid unset on links added with dangerouslySetInnerHTML */
a {
  text-decoration: underline;
}
